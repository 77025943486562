import MuxPlayer from "@mux/mux-player-react"; 

export default function MuxVideo() {
  return (
    <MuxPlayer
      streamType="on-demand"
      playbackId="YcCpYLqBiT7tYJfGta4VwrnT02f00jFhPG"
      primary-color="#FFFFFF"
      metadata={{
        video_id: "Lilly podcast introduccion",
        video_title: "Lilly Podcast",
      }
    } 
    />
  );
}
