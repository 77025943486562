import MuxVideo from "@components/mux-player"

import Image from "next/image"

import PageTitle from "@components/page-title"

import Footer from "@components/layout/footer"

const Index = () => {
  const areas = [
    "Diabetes",
    "Reumatología",
    "Dermatología",
    "Migraña",
    "Oncología",
    "Urología",
    "Osteoporosis",
  ]

  return (
    <div className="-mx-4 -my-6 ">
      <PageTitle pageTitle="Bienvenido" />
      <section className="bg-first-section bg-no-repeat	 bg-cover lg:bg-bottom">
        <header className="text-gray-600 body-font">
          <div className="mx-auto flex flex-wrap px-3 md:px-10 py-10 flex-col md:flex-row items-center">
            <a className="flex mb-4 md:mb-0">
              <Image
                src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/welcome/logo_podcast.svg`}
                alt="logo lilly"
                width={100}
                height={100}
                className="w-10/12 md:w-8/12 lg:w-10/12 mx-auto "
              />
            </a>
            <nav className="md:ml-auto flex  items-center text-base justify-center"></nav>
            <a
              href={
                process.env.NEXT_PUBLIC_PASSPORT_REGISTER! +
                '?title="Podcast"&url=' +
                process.env.NEXTAUTH_URL +
                "/login/passport"
              }
              className="welcome-btn w-56 text-white  hover:text-white border-2  font-semibold rounded-full text-sm md:text-lg px-3 py-1 text-center mr-2 mb-6 md:mb-2"
            >
              Regístrarse
            </a>
            <a
              href={
                process.env.NEXT_PUBLIC_PASSPORT_LOGIN! +
                '?title="Podcast"&url=' +
                process.env.NEXTAUTH_URL +
                "/login/passport"
              }
              className="welcome-btn w-56 text-center font-semibold text-red-lilly hover:text-white bg-white hover:bg-transparent  border-2 border-white   rounded-full text-sm md:text-lg px-3 py-1  mr-2 mb-6 md:mb-2  focus:text-white  "
            >
              Iniciar Sesión
            </a>
            <div className="p-1">
              <div className="bg-gray-100 rounded-2xl p-2 h-full items-center bg-gradient2">
                <span className="title-font text-sm text-white">
                  Si ya es usuario de Lilly Conexiones, puede entrar{" "}
                  <span className="font-bold">
                    con el mismo correo que <br className="hidden md:block" />{" "}
                    usa para Lilly Conexiones.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div className="px-4 py-6 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-7">
          <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
            <div className="flex flex-col justify-center">
              <div className="max-w-xl mb-6">
                <h2 className="text-white max-w-xl mb-6 text-4xl md:text-4xl  lg:text-5xl font-bold font-DIN ">
                  Programas de radio por internet a demanda
                </h2>
                <p className="text-white font-medium text-xl md:text-2xl font-arial lg:pr-40 ">
                  En <span className="font-bold">Lilly Podcast</span>, podrá
                  encontrar una amplia biblioteca con contenido para escuchar en
                  donde quiera y cuando quiera.
                </p>
              </div>

              <div className="align-left  md:pr-24 xl:pr-48 text-center">
                <div className="rounded-2xl p-5 bg-gradient1">
                  <span className=" text-xl text-white font-medium font-arial">
                    ¡Nuevos episodios cada semana!
                  </span>
                </div>
              </div>
            </div>
            <div>
              <MuxVideo />
            </div>
          </div>
        </div>
        <div className="px-20 py-4 md:py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-7xl md:px-24 lg:px-8 lg:py-10">
          <div className="grid grid-cols-1 md:row-gap-8 md:grid-cols-7 md:space-x-6 text-white font-bold text-center  ">
            {areas.map((area, index) => (
              <div key={index} className="my-3">
                <p className="border-solid border border-white rounded-lg py-2">
                  {area}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-second-section">
        <div className="px-4 py-12 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-5 mx-auto text-center lg:max-w-2xl">
            <p className=" text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
              Todo lo que puede encontrar en Lilly Podcast
            </p>
          </div>
        </div>
        <div className="px-4 py-2 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
          <div className="grid grid-cols-1 row-gap-8 md:grid-cols-3 font-Arial ">
            <div className="text-center md:border-r my-6">
              <img
                src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/welcome/icono_1.svg`}
                alt="mic"
                className="h-48 max-w-screen-lg mx-auto pb-2 md:pb-10 flex"
              />
              <p className="text-base font-medium tracking-widest text-white  lg:text-base">
                Speakers nacionales e <br />
                internacionales
              </p>
            </div>
            <div className="text-center md:border-r my-6">
              <img
                src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/welcome/icono_2.svg`}
                alt="hands"
                className="h-48 max-w-screen-lg mx-auto pb-2 mb:pb-10 flex"
              />
              <p className="text-base font-medium tracking-widest text-white  lg:text-base">
                Temas de actualidad y<br />
                de relevancia clínica
              </p>
            </div>
            <div className="text-center my-6">
              <img
                src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/welcome/icono_3.svg`}
                alt="play"
                className="h-48 max-w-screen-lg mx-auto pb-2 md:pb-10 flex"
              />
              <p className="text-base font-medium tracking-widest text-white  lg:text-base">
                Episodios nuevos
                <br /> cada semana
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="max-w-xl mb-5 md:mx-auto text-center lg:max-w-2xl">
            <p className="text-base text-white lg:text-lg">
              Cualquier pregunta, por favor contáctenos vía correo electrónico a{" "}
              <br />{" "}
              <a
                className="font-bold text-white"
                href="mailto:conexiones_soporte@lilly.com"
              >
                conexiones_soporte@lilly.com
              </a>
              <br />y con gusto le ayudaremos en su registro.
            </p>
          </div>
        </div>
        <div className="container mx-auto flex px-3 pt-4 items-center justify-center flex-col">
          <img
            src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/welcome/logo_lilly.svg`}
            alt=""
            className="w-50 h-20 mb-8 object-cover object-center  border-gray-200 "
          />
          <div className="text-center lg:w-2/3 w-full">
            <Footer />
            <div className="px-4 md:px-12 xl:px-28 pt-10">
              <div className="h-10 bg-gradient-to-r from-dark-lilly to-red-lilly rounded-t-full"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Index
