import Head from "next/head";

import p from "../data/meta-tags.json";

type IProps = {
  pageTitle?: string;
};

const PageTitle: React.FC<IProps> = ({ pageTitle }) => {
  return (
    <Head>
      <title>{`${pageTitle ? `${pageTitle} |` : ""} ${p.title}`}</title>
      <meta charSet="utf-8" />
      <link rel="shortcut icon" href="https://sinergisxbi.s3.amazonaws.com/lilly-podcast/lilly.png" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="title" content={p.title} />
    </Head>
  );
};

export default PageTitle;
